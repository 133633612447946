<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-33">
            <ProgrammeStatusesSelect
              v-model="filters.status"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-date
              v-model="filters.from"
              name="dateFrom"
              label="Start Date From"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <form-date
              v-model="filters.to"
              name="dateTo"
              label="Start Date To"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <AgentsSelect
              v-model="filters.agent_id"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <CountriesAutocomplete
              v-model="filters.country_id"
              label="Nationality"
            />
          </div>
          <div class="md-layout-item md-size-33">
            <LevelsSelect
              v-model="filters.course_level_id"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getAttendance"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormDate } from '@/components/Inputs';
import {
  CountriesAutocomplete,
  ProgrammeStatusesSelect,
  AgentsSelect,
  LevelsSelect,
} from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';

export default {
  components: {
    VueTable,
    FormDate,
    CountriesAutocomplete,
    ProgrammeStatusesSelect,
    AgentsSelect,
    LevelsSelect,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'student.student_number',
          mask: 'student no.',
          sortable: true,
        },
        {
          title: 'student.first_name',
          mask: 'given name',
          sortable: true,
        },
        {
          title: 'student.last_name',
          mask: 'surname',
          sortable: true,
        },
        {
          title: 'student.email',
          mask: 'email',
          sortable: true,
        },
        {
          title: 'student.country.nationality',
          mask: 'Nationality',
          sortable: true,
        },
        {
          title: 'programme.start_date',
          mask: 'start date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'programme.finish_date',
          mask: 'finish date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'programme.tuition_finish_date',
          mask: 'tuition finish date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'programme.status',
          mask: 'status',
          sortable: true,
        },
        {
          title: 'student.view_student_level.last_enrolment.classroom.classroom_code',
          mask: 'classroom',
          sortable: true,
        },
        {
          title: 'performed_days',
          mask: 'performed days',
          sortable: true,
        },
        {
          title: 'attended_days',
          mask: 'attended days',
          sortable: true,
        },
        {
          title: 'absent_days',
          mask: 'absent days',
          sortable: true,
        },
        {
          title: 'attendance_rate',
          mask: 'attendance rate',
          sortable: true,
          after: '%',
        },
        {
          title: 'max_attendance_rate',
          mask: 'max attendance rate',
          sortable: true,
          after: '%',
        },
        {
          title: 'programme.warnings.qty',
          mask: 'warnings',
          sortable: true,
        },
      ],
      values: {},
      actions: [],
    },
    filters: {
      from: null,
      to: null,
      status: 'Enrolled',
      country_id: null,
      course_level_id: null,
    },
    loading: false,
  }),
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAttendance(params) {
      this.loading = true;
      this.filters = {
        ...this.filters,

        // We add the params to filters,
        // to be able to download the XLS filtered
        // by the following params (sortBy, sortDir, search)
        page: params.page,
        per_page: params.per_page,
        sort_by: params.sortBy,
        sort_dir: params.sortDir,
        search: params.search,
      };

      this.request(this.$API.ATTENDANCE_REPORT, 'get', { ...this.filters }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onFilter() {
      this.$refs.vtable.init();
    },
    onDownload() {
      this.loading = true;
      this.request(`${this.$API.ATTENDANCE_REPORT}xls`, 'download', { ...this.filters }, ({ data }) => {
        const filename = `attendance_report_${moment().format('YYYY_MM_DD')}.xlsx`;
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>

</style>
